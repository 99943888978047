import { Component, OnInit } from '@angular/core';
declare var jQuery: any;
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.css']
})
export class CountdownComponent implements OnInit {

  private subscription: Subscription;
  
  public dateNow = new Date();
  public dDay = new Date('Jul 18 2021 14:00:00');
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute  = 60;

  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;

  constructor() { 

  }

  private getTimeDifference () {
    this.timeDifference = new  Date().getTime() - this.dDay.getTime();
    this.allocateTimeUnits(this.timeDifference);
}

private allocateTimeUnits (timeDifference) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
    if (this.daysToDday < 0 ) {
      this.daysToDday = this.daysToDday + 1;
    }
}

ngOnInit() {
   this.subscription = interval(1000)
       .subscribe(x => { this.getTimeDifference(); });
}

ngOnDestroy() {
  this.subscription.unsubscribe();
}


  }

